<h2 mat-dialog-title>Preview</h2>
<div mat-dialog-content>
  <div class="galleryHolder">
    <!-- <carousel
    [height] = "600"
    [width]="1000"
    [cellWidth]="1000"
    [images]="images">
  </carousel> -->
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
</div>
