import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  // get all accounting
  getInvoices($params = {}){
    return this.http.get(`${environment.apiUrl}/invoice/list`, { params : $params})
    .pipe(map((data:any) => {
      return data;
    }));
  }

  // get all invoices
  gettingInvoices($params = {}){
    return this.http.get(`${environment.apiUrl}/invoice/getAllInvoices`, { params : $params})
    .pipe(map((data: any) => {
      return data;
    }));
  }

// save accounting
  saveInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/save`, formData, { observe: 'events',  reportProgress: true });
  }

  // save invoice
  savingInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/saveInvoice`, formData, { observe: 'events',  reportProgress: true });
  }

  // update invoice
  updateInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/updateInvoiceData`, formData, { observe: 'events', reportProgress: true});
  }

  // get accounting
  getInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/get`, formData);
  }

  // get invoice
  gettingInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/getInvoiceData`, formData);
  }

  // delete  accounting
  deleteInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/delete`, formData);
  }

  // delete invoice
  deletingInvoice(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/deleteInvoice`, formData);
  }
  getInvoiceHistory(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/history`, formData);
  }

  getInvoiceFiles(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/files`, formData);
  }

  saveInvoiceFiles(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/savefiles`, formData, { observe: 'events',  reportProgress: true });
  }

  deleteInvoiceFile(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoice/deletefile`, formData);
  }
}
