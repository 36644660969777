import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthinterceptorInterceptor } from './helpers/authinterceptor.interceptor';
import { ErrorinterceptorInterceptor } from './helpers/errorinterceptor.interceptor';
import { RouterModule } from '@angular/router';

// fix reload page error 404
import {LocationStrategy, HashLocationStrategy, CommonModule} from '@angular/common';
import { MaterialModule } from './shared/material/material.module';
import { DefaultModule } from './layout/default/default.module';
import { AdminRoutingModule } from './modules/admin/admin-routing.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ContainerRoutingModule } from './modules/container/container-routing.module';
import { CommonpipesModule } from './modules/commonpipes/commonpipes.module';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { SharedModule } from './shared/shared.module';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { SkeletonLoaderModule } from './shared/utils/skeleton-loader/skeleton-loader.module';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { TitleDescriptionsComponent } from './modules/setting/title-descriptions/title-descriptions.component';

@NgModule({
  declarations: [
    AppComponent,
    TitleDescriptionsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    CommonModule,
    RouterModule,
    MaterialModule,
    DefaultModule,
    AdminRoutingModule,
    NgxMaterialTimepickerModule,
    ContainerRoutingModule,
    CommonpipesModule,
    AngularFileUploaderModule,
    SharedModule,
    ImageViewerModule,
    SkeletonLoaderModule,
    GalleryModule,
    LightboxModule,
    FormsModule,
    CommonpipesModule,
    DpDatePickerModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthinterceptorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorinterceptorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy } // fix reload page error 404
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
