<div class="cs-page-header">
    <span><mat-icon mat-list-icon>local_shipping</mat-icon> Local Transportations</span>
    <div class="cs-right">

        <button *ngIf="pms.update" mat-flat-button (click)="openDiv()"> <mat-icon mat-list-icon>add</mat-icon>Add Local</button>
        <button mat-flat-button (click)="printPage()"><svg><use xlink:href="#print_ico"></use></svg></button>
        <button mat-flat-button (click)="savePDF()"><svg><use xlink:href="#pdf_ico"></use></svg></button>
        <!-- <button mat-flat-button (click)="saveEXCEL()"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
      </svg></button> -->

    </div>
</div>
<div class="cs-box">

    <div class="form-local" *ngIf="addNewLocal">
        <form [formGroup]="localForm" (submit)="onSubmit()">

            <mat-form-field>
                <mat-label>LOCATION</mat-label>
                <input type="text" matInput formControlName="LOCATION">
            </mat-form-field>
            <mat-form-field>
                <mat-label>SAVANNAH</mat-label>
                <input type="text" matInput formControlName="SAVANNAH">
            </mat-form-field>
            <mat-form-field>
                <mat-label>CALIFORNIA</mat-label>
                <input type="text" matInput formControlName="CALIFORNIA">
            </mat-form-field>
            <mat-form-field>
                <mat-label>NEWYORK</mat-label>
                <input type="text" matInput formControlName="NEWYORK">
            </mat-form-field>
            <mat-form-field>
                <mat-label>TEXAS</mat-label>
                <input type="text" matInput formControlName="TEXAS">
            </mat-form-field>
            <button mat-raised-button type="submit" color="warn" [disabled]="!localForm.valid"> save <mat-icon>save</mat-icon></button>

        </form>
    </div>

    <div class="form-filter">
        <form>
            <mat-form-field>
                <mat-label>Location</mat-label>
                <input type="text" matInput [formControl]="locationCtrl">
            </mat-form-field>

            <div class="filter-btn">
                <button mat-raised-button color="warn" (click)="resetFilter()">Reset</button>
            </div>
            <div class="filter-btn" style="float: right; margin-top: 1%;">
                <button mat-raised-button color="warn" (click)="sendMail()"> Send Mail</button>
            </div>
        </form>

    </div>

    <div class="mat-elevation-z8">
        <div *ngIf="loaded; else rowsPlaceHolder"></div>
        <div class="loading-shade" *ngIf="loaded && (isLoadingResults || isRateLimitReached)">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>

        <table mat-table [dataSource]="dataSource" [hidden]="!loaded" matSort matSortDisableClear matSortActive="id" matSortDirection="asc" id="content">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="LOCATION">
                <th mat-header-cell *matHeaderCellDef> LOCATIONS </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.isEdit">
                        <input matInput [type]="element.type" value="{{element.LOCATIONS}}" (change)="onchange(element, $event, 'LOCATION')">
                    </div>
                    <div *ngIf="!element.isEdit">
                        <input readonly matInput [type]="element.type" value="{{element.LOCATIONS}}" (change)="onchange(element, $event, 'LOCATION')">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="SAVANNAH">
                <th mat-header-cell *matHeaderCellDef> SAVANNAH </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.isEdit">
                        <input matInput [type]="element.type" value="{{element.SAVANNAH}}" (change)="onchange(element, $event, 'SAVANNAH')">
                    </div>
                    <div *ngIf="!element.isEdit">
                        <input readonly matInput [type]="element.type" value="{{element.SAVANNAH}}" (change)="onchange(element, $event, 'SAVANNAH')">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="CALIFORNIA">
                <th mat-header-cell *matHeaderCellDef> CALIFORNIA </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.isEdit">
                        <input matInput [type]="element.type" value="{{element.CALIFORNIA}}" (change)="onchange(element, $event, 'CALIFORNIA')">
                    </div>
                    <div *ngIf="!element.isEdit">
                        <input readonly matInput [type]="element.type" value="{{element.CALIFORNIA}}" (change)="onchange(element, $event, 'CALIFORNIA')">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="NEWYORK">
                <th mat-header-cell *matHeaderCellDef> NEWYORK </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.isEdit">
                        <input matInput [type]="element.type" value="{{element.NEWYORK}}" (change)="onchange(element, $event, 'NEWYORK')">
                    </div>
                    <div *ngIf="!element.isEdit">
                        <input readonly matInput [type]="element.type" value="{{element.NEWYORK}}" (change)="onchange(element, $event, 'NEWYORK')">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="TEXAS">
                <th mat-header-cell *matHeaderCellDef> TEXAS </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.isEdit">
                        <input matInput [type]="element.type" value="{{element.TEXAS}}" (change)="onchange(element, $event, 'TEXAS')">
                    </div>
                    <div *ngIf="!element.isEdit">
                        <input readonly matInput [type]="element.type" value="{{element.TEXAS}}" (change)="onchange(element, $event, 'TEXAS')">
                    </div>
                </td>
            </ng-container>

            <!-- update Column -->
              <ng-container matColumnDef="update" *ngIf="pms.update">
                <th mat-header-cell *matHeaderCellDef style="width: 7%;"> update </th>
                <td mat-cell *matCellDef="let element" >
                    <!-- open update btn -->
                    <button *ngIf="!element.isEdit" [disabled]="!pms.update" style="color: #198dca;" mat-icon-button aria-label="Edit" (click)="openUpdateRow(element)">
  <mat-icon mat-list-icon>edit</mat-icon>
  </button>
                    <!-- update btn -->
                    <button *ngIf="element.isEdit" style="color: #108d10;" mat-icon-button aria-label="Edit" (click)="editRow(element.id, element.LOCATIONS, element.SAVANNAH, element.CALIFORNIA, element.NEWYORK, element.TEXAS, element)">
  <mat-icon mat-list-icon>save</mat-icon>
</button>
                    <button *ngIf="element.isEdit" style="color: #198dca;" mat-icon-button aria-label="Edit" (click)="closeUpdateRow(element)">
  <mat-icon mat-list-icon>cancel</mat-icon>
</button>

                </td>
            </ng-container>


            <!-- Delete Column -->
            <ng-container matColumnDef="delete" *ngIf="pms.update">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element">
                    <button style="color: rgb(218, 70, 70);" mat-icon-button aria-label="delete" (click)="deleteRow(element.id)">
    <mat-icon mat-list-icon>delete</mat-icon>
  </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{locationCtrl.value}}"</td>
            </tr>

        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30, 100, resultsLength]" [length]="resultsLength" showFirstLastButtons></mat-paginator>
    </div>
</div>



<ng-template #rowsPlaceHolder>
    <app-skeleton type="rows"></app-skeleton>
</ng-template>
