<ng-template #packageItem let-package="package">
  <td *ngIf="package.type == 1">
      <div>{{ package.year }} {{ package.model }} {{ package.mark }}</div>
      <div><b>{{ package.vin }}</b></div>
  </td>
  <td *ngIf="package.type == 2">
      <div>{{ package.description }}</div>
      <!-- <div>{{ package.value }}</div> -->
  </td>
</ng-template>

<div class="cs-page-header">
  <span><mat-icon mat-list-icon>view_quilt</mat-icon> Containers</span>
  <div class="cs-right">
      <a [routerLink]="'/admin/containers/add'" mat-flat-button mat-ripple *ngIf="pms.add">
          <mat-icon mat-list-icon>add_circle_outline</mat-icon> Add New Container
      </a>
      <button mat-flat-button (click)="printPage()"><svg><use xlink:href="#print_ico"></use></svg></button>
      <button mat-flat-button (click)="savePDF()"><svg><use xlink:href="#pdf_ico"></use></svg></button>
      <button mat-flat-button (click)="saveEXCEL()"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
    </svg></button>

      <!-- <button mat-raised-button><mat-icon mat-list-icon>add_circle_outline</mat-icon> Add New Container</button> -->
  </div>
</div>
<div class="cs-box">
  <div class="form-filter">
      <form>
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input type="text" matInput [formControl]="container_id">
      </mat-form-field>
          <mat-form-field>
              <mat-label>Booking No</mat-label>
              <input type="text" matInput [formControl]="bookingNo">
          </mat-form-field>

          <mat-form-field>
              <mat-label>Container No</mat-label>
              <input type="text" matInput [formControl]="containerNo">
          </mat-form-field>

          <mat-form-field>
              <mat-label>Port Of Loading</mat-label>
              <input type="text" matInput [formControl]="polCtrl">
          </mat-form-field>

          <mat-form-field>
              <mat-label>Port Of Discharge</mat-label>
              <input type="text" matInput [formControl]="podCtrl">
          </mat-form-field>

          <mat-form-field>
              <mat-label>Packages Description</mat-label>
              <input type="text" matInput [formControl]="package">
          </mat-form-field>


          <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [formControl]="containerStatusId">
                  <mat-option value="">All Statuses</mat-option>
                  <mat-option *ngFor="let option of container_statuses" [value]="option.id">
                      {{option.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate [formControl]="from_date" placeholder="Start date">
                  <input matEndDate [formControl]="to_date" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <!-- <mat-form-field>
              <mat-label>From Date</mat-label>
              <input matInput [matDatepicker]="pickpicker" [formControl]="from_date" placeholder="From Date">
              <mat-datepicker-toggle matSuffix [for]="pickpicker"></mat-datepicker-toggle>
              <mat-datepicker #pickpicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
              <mat-label>To Date</mat-label>
              <input matInput [matDatepicker]="pickpicker2" [formControl]="to_date" placeholder="To Date">
              <mat-datepicker-toggle matSuffix [for]="pickpicker2"></mat-datepicker-toggle>
              <mat-datepicker #pickpicker2></mat-datepicker>
          </mat-form-field> -->

          <div class="filter-btn">
              <button mat-raised-button color="warn" (click)="resetFilter()">Reset</button>
          </div>

      </form>

  </div>

  <div class="mat-elevation-z8">
      <div *ngIf="loaded; else rowsPlaceHolder"></div>
      <div class="loading-shade" *ngIf="loaded && (isLoadingResults || isRateLimitReached)">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>

      <table mat-table [hidden]="!loaded" [dataSource]="dataSource" matSort matSortDisableClear matSortActive="shipment_id" matSortDirection="desc" id="content">
          <!--container id column-->
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>ID</th>
              <td mat-cell *matCellDef="let element"> {{element.container_id}} </td>
          </ng-container>

          <!-- Booking Column -->
          <ng-container matColumnDef="booking_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Booking No </th>
              <td mat-cell *matCellDef="let element"> {{element.booking_no}} </td>
          </ng-container>

          <!-- Container Column -->
          <ng-container matColumnDef="container_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Container No </th>
              <td mat-cell *matCellDef="let element"> {{element.container_no}} </td>
          </ng-container>

          <!-- Port Of Loading Column -->
          <ng-container matColumnDef="port_of_loading">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Port Of Loading </th>
              <td mat-cell *matCellDef="let element"> {{element.port_of_loading}} </td>
          </ng-container>

          <!-- Port Of Discharge Column -->
          <ng-container matColumnDef="port_of_discharge">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Port Of Discharge </th>
              <td mat-cell *matCellDef="let element"> {{element.port_of_discharge}} </td>
          </ng-container>

          <!-- Packages Column -->
          <ng-container matColumnDef="packages">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
              <!-- <td mat-cell *matCellDef="let element"> {{element.packages}} </td> -->
              <td mat-cell *matCellDef="let element">
                  <!-- <table>
                      <tr *ngFor="let package of element.packages;let i = index">
                          <ng-container [ngTemplateOutlet]="packageItem" [ngTemplateOutletContext]="{ package : package }"></ng-container>
                      </tr>
                  </table> -->

                  <div *ngFor="let package of element.packages;let i = index">
                      <p [ngTemplateOutlet]="packageItem" [ngTemplateOutletContext]="{ package : package }"></p>
                  </div>
              </td>
          </ng-container>

          <!-- Sailing Column -->
          <ng-container matColumnDef="sailing_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Sailing Date </th>
              <td mat-cell *matCellDef="let element"> {{element.sailing_date | date }} </td>
          </ng-container>

          <!-- Courier Column -->
          <ng-container matColumnDef="courier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Courier </th>
              <td mat-cell *matCellDef="let element"> <a [href]="generateLink(element.courier_url)" target="_blank">{{element.courier_name}}</a> </td>
          </ng-container>

          <!-- Eta Column -->
          <ng-container matColumnDef="eta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Eta </th>
              <td mat-cell *matCellDef="let element"> {{element.eta | date}} </td>
          </ng-container>

          <!-- Image Column -->
          <!-- <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef> Gallery </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="warn" (click)="openPhoto(element.container_id)">
          <mat-icon>image</mat-icon>
        </button>
      </td>
    </ng-container> -->

          <!-- Files Column -->
          <!-- <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef> Files </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="warn" (click)="openFiles(element.container_id)">
          <mat-icon>attach_file</mat-icon>
        </button>
      </td>
    </ng-container> -->


          <!-- Status Column -->
          <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.container_status}} </td>
          </ng-container>

          <!-- View Column -->
          <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef> View </th>
              <td mat-cell *matCellDef="let element">
                  <button mat-icon-button color="warn" [routerLink]="['/admin/containers/info', element.container_id]">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
              </td>
          </ng-container>

          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
          <mat-icon>more_vert</mat-icon>
        </button>
                  <mat-menu #menu="matMenu">
                      <button mat-menu-item color="warn" (click)="openPhoto(element.container_id)">
            <mat-icon>image</mat-icon>
            <span>Container Gallery</span>
          </button>

                      <button mat-menu-item color="warn" (click)="openPhoto2(element.shipment_id)">
            <mat-icon>image</mat-icon>
            <span>Shipment Gallery</span>
          </button>

                      <button mat-menu-item color="warn" (click)="openFiles(element.container_id)">
            <mat-icon>attach_file</mat-icon>
            <span>Container Files</span>
          </button>

                      <button mat-menu-item color="warn" (click)="openFiles2(element.shipment_id)">
            <mat-icon>attach_file</mat-icon>
            <span>Shipment Files</span>
          </button>

                      <ng-container *ngIf="pms.update">
                          <button mat-menu-item aria-label="Edit" [routerLink]="['/admin/containers/', element.container_id]">
              <mat-icon mat-list-icon>edit</mat-icon>
              <span>Update</span>
            </button>
                      </ng-container>

                      <ng-container *ngIf="pms.delete">
                          <button mat-menu-item aria-label="Edit" (click)="deleteContainer(element.container_id)">
              <mat-icon mat-list-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
                      </ng-container>
                  </mat-menu>
              </td>
          </ng-container>


          <!-- Print Column -->
          <!-- <ng-container matColumnDef="print">
      <th mat-header-cell *matHeaderCellDef> Print </th>
      <td mat-cell *matCellDef="let element"> <mat-icon mat-list-icon>print</mat-icon> </td>
    </ng-container> -->

          <!-- Update Column -->

          <!--
    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef> Update </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="Edit" [routerLink]="['/admin/containers/', element.container_id]">
          <mat-icon mat-list-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
  -->

          <!-- Delete Column -->
          <!--
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="Edit" (click)="deleteContainer(element.container_id)">
          <mat-icon mat-list-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
  -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 20, 30, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>

<ng-template #rowsPlaceHolder>
  <app-skeleton type="rows"></app-skeleton>
</ng-template>


<ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
  <ng-container *ngIf="type === 'imageViewer' && index === currIndex">
      <ngx-imageviewer [src]="data.src"></ngx-imageviewer>
  </ng-container>
</ng-template>
