// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   home : 'http://localhost:4200/',
//   apiUrl:'http://localhost/HM-CARGO-SYS/apis/index.php?route=api',
//   assetsPrefix: ''
// };
/*
export const environment = {
  production: true,
  home: 'http://localhost/hm-cargosystem/',
  apiUrl: 'http://localhost/hm-cargosystem/apis/index.php?route=api',
  assetsPrefix: '/'
};
*/
export const environment = {
  production: true,
  home: 'https://cargo-system.hmcargollc.com/',
  apiUrl: 'https://cargo-system.hmcargollc.com/apis/index.php?route=api',
  assetsPrefix: '/'
};
/*

export const environment = {
  production: true,
  home: 'https://beta.cargo-system.hmcargollc.com/',
  apiUrl: 'https://beta.cargo-system.hmcargollc.com/apis/index.php?route=api',
  assetsPrefix: '/'
};
*/

/*
 export const environment = {
  production: false,
  home : 'http://localhost:4200/',
  //apiUrl:'https://dev.aswaqy.net/index.php/api',
  apiUrl:'https://tradeway-eg.com/apis/index.php?route=api'
 // apiUrl:'https://localhost:7272/api',

}; */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
