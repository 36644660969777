<div class="cs-page-header">
    <span><mat-icon mat-list-icon>local_shipping</mat-icon> Local Transportations</span>
    <div class="cs-right">
        <button mat-raised-button (click)="download()"><mat-icon mat-list-icon class="aicon">save_alt</mat-icon> Export Data</button>
        <!-- <span *ngIf="submited">{{ percentCompleted }}%</span> -->
        <label class="cs-dlnk" mat-ripple for="inp_excel" *ngIf="pms.update">
      <mat-icon mat-list-icon>subdirectory_arrow_right</mat-icon>  Update the Excel
    </label>
        <input type="file" style="display:none;" id="inp_excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="onFileChange($event)" />

        <button mat-flat-button (click)="printPage()"><svg><use xlink:href="#print_ico"></use></svg></button>
        <button mat-flat-button (click)="savePDF()"><svg><use xlink:href="#pdf_ico"></use></svg></button>

    </div>
</div>
<div class="cs-box">
    <div class="form-filter">
        <form>
            <mat-form-field>
                <mat-label>Location</mat-label>
                <input type="text" matInput [formControl]="locationCtrl">
            </mat-form-field>

            <div class="filter-btn">
                <button mat-raised-button color="warn" (click)="resetFilter()">Reset</button>
            </div>
        </form>

    </div>

    <div *ngIf="loaded; else rowsPlaceHolder">
        <table mat-table [dataSource]="locations$" class="mat-elevation-z8" id="content">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
    </div>
</div>



<ng-template #rowsPlaceHolder>
    <app-skeleton type="rows"></app-skeleton>
</ng-template>
