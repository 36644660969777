<h1 mat-dialog-title>
  <span *ngIf="!editMode">Add New</span>
  <span *ngIf="editMode">Update</span>
  </h1>
<div class="field-full" [formGroup]="dataForm">

  <mat-form-field>
    <mat-label>Receiver Name</mat-label>
    <input type="text" matInput formControlName="name">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Phone Number</mat-label>
    <input type="text" matInput matInput formControlName="phone">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Address</mat-label>
    <input type="text" matInput matInput formControlName="address">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Email</mat-label>
    <input type="text" matInput matInput formControlName="email">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select formControlName="status">
      <mat-option [value]="1">Enable</mat-option>
      <mat-option [value]="0">Disable</mat-option>
    </mat-select>
  </mat-form-field>

</div>



<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="warn" (click)="submitForm()" [disabled]="!dataForm.valid" *ngIf="!submitted">
    <span *ngIf="!editMode">Submit</span>
    <span *ngIf="editMode">Update</span>
  </button>
</mat-dialog-actions>
