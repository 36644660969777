<div class="cs-page-header">
  <span><mat-icon mat-list-icon>edit</mat-icon> Signatures</span>
</div>
<div class="cs-box">
  <div class="mat-elevation-z8"  *ngIf="loaded; else rowsPlaceHolder">
    <table mat-table [dataSource]="dataSource" >
      <!-- Container Column -->
      <ng-container matColumnDef="booking_no">
        <th mat-header-cell *matHeaderCellDef> Booking No </th>
        <td mat-cell *matCellDef="let element"> {{element.booking_no}} </td>
      </ng-container>

      <!-- Container Column -->
      <ng-container matColumnDef="container_no">
        <th mat-header-cell *matHeaderCellDef> Container No </th>
        <td mat-cell *matCellDef="let element"> {{element.container_no}} </td>
      </ng-container>

      <!-- Shipment Column -->
      <ng-container matColumnDef="shipment_no">
        <th mat-header-cell *matHeaderCellDef> Shipment No </th>
        <td mat-cell *matCellDef="let element"> {{element.shipment_id}} </td>
      </ng-container>


      <!-- Container Column -->
      <ng-container matColumnDef="shipper_name">
        <th mat-header-cell *matHeaderCellDef> Shipper </th>
        <td mat-cell *matCellDef="let element"> {{element.shipper_name}} </td>
      </ng-container>

      <!-- Image Column -->
      <!--<ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="warn" (click)="openPhoto(element.contract)">
            <mat-icon>image</mat-icon>
          </button>
        </td>
      </ng-container>-->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="warn" (click)="viewContract(element)">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>


      <!-- Image Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef> Download </th>
        <td mat-cell *matCellDef="let element">
          <a class="inner_link" [href]="element.download" target="_blank"><mat-icon>cloud_download</mat-icon></a>
        </td>
      </ng-container>

      <!-- Update Column -->
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef> Sign </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="Sign" (click)="signContract(element)">
            <mat-icon mat-list-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>



<ng-template #rowsPlaceHolder>
  <app-skeleton type="rows"></app-skeleton>
</ng-template>


<ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
	<ng-container *ngIf="type === 'imageViewer' && index === currIndex">
    <ngx-imageviewer [src]="data.src"></ngx-imageviewer>
  </ng-container>
</ng-template>
