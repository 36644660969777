<div class="cs-page-header">
  <span><mat-icon mat-list-icon>toc</mat-icon> Address Book</span>
  <div class="cs-right">

    <button mat-flat-button (click)="printPage()"><svg><use xlink:href="#print_ico"></use></svg></button>
    <button mat-flat-button (click)="savePDF()"><svg><use xlink:href="#pdf_ico"></use></svg></button>

  </div>
</div>
<div class="cs-box" id="content">
  <mat-tab-group dynamicHeight>
    <mat-tab label="Shipper Book Address">
      <div class="strow">
        <button mat-raised-button color="warn" (click)="openShipperDialog()">Add New Shipper Address</button>
      </div>

      <div class="cs-tab-content mat-elevation-z4">
        <div class="mat-elevation-z8">
          <div *ngIf="loaded; else rowsPlaceHolder"></div>
          <div class="loading-shade" *ngIf="loaded && (isSLoadingResults || isSRateLimitReached)">
            <mat-spinner *ngIf="isSLoadingResults"></mat-spinner>
          </div>

          <table mat-table [hidden]="!loaded" [dataSource]="shipper_dataSource" #shipperSort matSort matSortDisableClear matSortActive="shipper_id" matSortDirection="desc" id="content">
            <!-- Shipper Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Shipper Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Phone Number Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number </th>
              <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef> Address </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>


            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Enabled </th>
              <td mat-cell *matCellDef="let element"> {{element.status | yesno}} </td>
            </ng-container>

            <!-- Update Column -->
            <ng-container matColumnDef="update">
              <th mat-header-cell *matHeaderCellDef> Update </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="Edit" (click)="openShipperDialog(element)">
                  <mat-icon mat-list-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> Delete </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="Edit" (click)="deleteShipper(element.shipper_id)">
                  <mat-icon mat-list-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsShipper"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsShipper;"></tr>
          </table>
          <mat-paginator #shipperPaginator [length]="resultsSLength" [pageSizeOptions]="[10, 20, 30, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Receiver Book Address">
      <div class="cs-tab-content mat-elevation-z4">
        <div class="strow">
          <button mat-raised-button color="warn" (click)="openReceiverDialog()">Add New Receiver Address</button>
        </div>
        <div class="mat-elevation-z8">
          <div *ngIf="loadedR; else rowsPlaceHolder"></div>
          <div class="loading-shade" *ngIf="loadedR && (isRLoadingResults || isRRateLimitReached)">
            <mat-spinner *ngIf="isRLoadingResults"></mat-spinner>
          </div>

          <table mat-table [hidden]="!loadedR" [dataSource]="receiver_dataSource" matSort #receiverSort matSortDisableClear matSortActive="receiver_id" matSortDirection="desc" id="content2">
            <!-- Shipper Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Receiver Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Phone Number Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number </th>
              <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef> Address </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>


            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Enabled </th>
              <td mat-cell *matCellDef="let element"> {{element.status | yesno}} </td>
            </ng-container>

            <!-- Update Column -->
            <ng-container matColumnDef="update">
              <th mat-header-cell *matHeaderCellDef> Update </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="Edit" (click)="openReceiverDialog(element)">
                  <mat-icon mat-list-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> Delete </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="Edit" (click)="deleteReceiver(element.receiver_id)">
                  <mat-icon mat-list-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsReceiver"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsReceiver;"></tr>
          </table>
          <mat-paginator #receiverPaginator [length]="resultsRLength" [pageSizeOptions]="[10, 20, 30, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>



<ng-template #rowsPlaceHolder>
  <app-skeleton type="rows"></app-skeleton>
</ng-template>
