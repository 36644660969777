<div class="cs-page-header">
    <span><mat-icon mat-list-icon>view_quilt</mat-icon> Shared Containers</span>
    <div class="cs-right">
        <a [routerLink]="'/admin/shipments/add'" mat-flat-button *ngIf="pms.add">
            <mat-icon mat-list-icon>add_circle_outline</mat-icon> Add Shipment
        </a>
        <!-- <button mat-flat-button (click)="printPage()"><svg><use xlink:href="#print_ico"></use></svg></button>
    <button mat-flat-button (click)="savePDF()"><svg><use xlink:href="#pdf_ico"></use></svg></button> -->
    </div>
</div>
<div class="cs-box">
    <div class="form-filter">
        <form>
            <mat-form-field>
                <mat-label>Warehouse</mat-label>
                <mat-select [formControl]="warehouseId">
                    <mat-option value="">All Warehouses</mat-option>
                    <mat-option *ngFor="let option of warehouses_list" [value]="option.id">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Destination</mat-label>
                <input type="text" matInput [formControl]="destinationName">
            </mat-form-field>



            <div class="filter-btn">
                <button mat-raised-button color="warn" (click)="resetFilter()">Reset</button>
            </div>
        </form>

    </div>
    <div class="mat-elevation-z8">
        <div *ngIf="loaded; else rowsPlaceHolder">

        </div>
        <table [hidden]="!loaded" mat-table [dataSource]="dataSource" multiTemplateDataRows #content id="content">

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="sp-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="sp-element-diagram">

                            <div *ngFor="let itm_package of element.packages">

                                <h2 *ngIf="itm_package.title !== 'test'" class="pktitle">{{ itm_package.shipper_name }} <span style="display: inline-block;padding-left: 100px;">Title : {{ itm_package.title | yesno }}</span></h2>

                                <div *ngFor="let package of itm_package.packages;let i = index">
                                    <div class="pck1" *ngIf="package.type == 1">

                                        <div>
                                            <h2>Year</h2>
                                            <h4>{{ package.year }}</h4>
                                        </div>

                                        <div>
                                            <h2>Model</h2>
                                            <h4>{{ package.model }}</h4>

                                        </div>

                                        <div>
                                            <h2>Make</h2>
                                            <h4>{{ package.mark }}</h4>
                                        </div>

                                        <div>
                                            <h2>Vin</h2>
                                            <h4>{{ package.vin }}</h4>
                                        </div>
                                    </div>
                                    <div class="pck2" *ngIf="package.type == 2">
                                        <div>
                                            <h2>Description</h2>
                                            <h4>{{ package.description }}</h4>
                                        </div>

                                    </div>
                                    <div class="pck3`" *ngIf="package.type== undefined">
                                      <div>
                                         <h1>No Packages</h1>
                                      </div>

                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="destination_name">
                <th mat-header-cell *matHeaderCellDef> Destination </th>
                <td mat-cell *matCellDef="let element"> {{element.destination_name}} </td>
            </ng-container>

            <ng-container matColumnDef="warehouse_name">
                <th mat-header-cell *matHeaderCellDef> Warehouse </th>
                <td mat-cell *matCellDef="let element"> {{element.warehouse_name | none}} </td>
            </ng-container>

            <ng-container matColumnDef="total_shipments">
                <th mat-header-cell *matHeaderCellDef> Total Shipments </th>
                <td mat-cell *matCellDef="let element"> {{element.total_shipments }} </td>
            </ng-container>


            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef> View </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="warn" (click)="expandedElement = expandedElement === element ? null : element">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="sp-element-row" [class.sp-expanded-row]="expandedElement === element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="sp-detail-row"></tr>


            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="sp-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="sp-detail-row"></tr> -->
        </table>

    </div>
</div>


<ng-template #rowsPlaceHolder>
    <app-skeleton type="rows"></app-skeleton>
</ng-template>
