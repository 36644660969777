<div class="profile">
    <img [src]="baseurl + 'assets/images/logo.jpeg'" />
    <i>

    <img [src]="image"/>
  </i>

    <span>{{ username }}</span>
</div>
<mat-list>

    <a mat-list-item matRipple [routerLink]="'/admin/shipments/add'">
        <mat-icon mat-list-icon>add</mat-icon>
        Add Shipment
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/shipments/list'" routerLinkActive="active">
        <mat-icon mat-list-icon>stars</mat-icon>
        Shipments
    </a>

    <mat-divider></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/containers/list'" routerLinkActive="active">
        <mat-icon mat-list-icon>view_quilt</mat-icon>
        Containers
    </a>

    <mat-divider *ngIf="pms.container.view_shared_container"></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/containers/shared'" routerLinkActive="active" *ngIf="pms.container.view_shared_container">
        <mat-icon mat-list-icon>view_quilt</mat-icon>
        Shared Containers
    </a>
    <ng-container>
        <mat-divider *ngIf="pms.accounting.view"></mat-divider>
        <a mat-list-item matRipple [routerLink]="'/admin/accountings/list'" routerLinkActive="active" *ngIf="pms.accounting.view">
            <mat-icon mat-list-icon>money</mat-icon>
            Accounting
        </a>
    </ng-container>
    <ng-container>

    </ng-container>
    <ng-container>
        <mat-divider *ngIf="pms.accounting.view"></mat-divider>
        <a mat-list-item matRipple [routerLink]="'/admin/invoice/list'" routerLinkActive="active" *ngIf="pms.invoice.view">
            <mat-icon mat-list-icon>money</mat-icon>
            Invoices
        </a>
    </ng-container>

    <!-- <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon mat-list-icon>format_quote</mat-icon>
                    Quotes
                </mat-panel-title>
            </mat-expansion-panel-header> -->
    <!-- new quotes -->
    <!-- <mat-divider *ngIf="pms.quotes.view"></mat-divider>
            <a mat-list-item matRipple [routerLink]="'/admin/quote2022/list'" routerLinkActive="active" *ngIf="pms.quotes.view && pms.quotes.update">
                Quotes 2022
            </a>
            <a mat-list-item matRipple [routerLink]="'/admin/quote2022/view'" routerLinkActive="active" *ngIf="pms.quotes.view && !pms.quotes.update">
                Quotes 2022
            </a> -->
    <!-- old quotes -->
    <!-- <mat-divider *ngIf="pms.quotes.view"></mat-divider>
            <a mat-list-item matRipple [routerLink]="'/admin/quote/list'" routerLinkActive="active" *ngIf="pms.quotes.view && pms.quotes.update">
                Quotes 2021
            </a>
            <a mat-list-item matRipple [routerLink]="'/admin/quote/view'" routerLinkActive="active" *ngIf="pms.quotes.view && !pms.quotes.update">
                Quotes 2021
            </a>
        </mat-expansion-panel>
    </mat-accordion> -->

    <!-- new quotes -->
    <mat-divider *ngIf="pms.quotes.view"></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/quote2022/list'" routerLinkActive="active" *ngIf="pms.quotes.view && pms.quotes.update">
        <mat-icon mat-list-icon>format_quote</mat-icon>
        Quotes
    </a>
    <a mat-list-item matRipple [routerLink]="'/admin/quote2022/view'" routerLinkActive="active" *ngIf="pms.quotes.view && !pms.quotes.update">
        <mat-icon mat-list-icon>format_quote</mat-icon>
        Quotes
    </a>

    <!-- old quotes -->
    <!-- <mat-divider *ngIf="pms.quotes.view"></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/quote/list'" routerLinkActive="active" *ngIf="pms.quotes.view && pms.quotes.update">
        <mat-icon mat-list-icon>format_quote</mat-icon>
        Quotes
    </a>
    <a mat-list-item matRipple [routerLink]="'/admin/quote/view'" routerLinkActive="active" *ngIf="pms.quotes.view && !pms.quotes.update">
        <mat-icon mat-list-icon>format_quote</mat-icon>
        Quotes
    </a> -->

    <!-- <mat-accordion *ngIf="pms.localtransportation.view">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon mat-list-icon>local_shipping</mat-icon>
                    Local Transportations
                </mat-panel-title>
            </mat-expansion-panel-header> -->
    <!-- new Local Transportations -->
    <!-- <mat-divider *ngIf="pms.localtransportation.view"></mat-divider>
            <a mat-list-item matRipple [routerLink]="'/admin/createlocaltransportantions'" routerLinkActive="active">
                New Local Transportations
            </a> -->
    <!-- old Local Transportations -->
    <!-- <mat-divider *ngIf="pms.localtransportation.view"></mat-divider>
            <a mat-list-item matRipple [routerLink]="'/admin/localtransportantions'" routerLinkActive="active">
               Old Local Transportations
            </a>
        </mat-expansion-panel>
    </mat-accordion> -->


    <!-- <mat-divider *ngIf="pms.localtransportation.view"></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/localtransportantions'" routerLinkActive="active" *ngIf="pms.localtransportation.view">
        <mat-icon mat-list-icon>local_shipping</mat-icon>
        Local Transportations
    </a> -->
    <!-- new Local Transportations -->
    <mat-divider *ngIf="pms.localtransportation.view"></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/createlocaltransportantions'" routerLinkActive="active" *ngIf="pms.localtransportation.view">
        <mat-icon mat-list-icon>local_shipping</mat-icon>
        Local Transportations
    </a>

    <mat-divider></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/address'" routerLinkActive="active">
        <mat-icon mat-list-icon>toc</mat-icon>
        Address Book
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/signature'" routerLinkActive="active" *ngIf="signatures || pms.general.signatures">
        <mat-icon mat-list-icon>edit</mat-icon>
        Signatures
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item matRipple [routerLink]="'/admin/shipments/Archivedlist'" routerLinkActive="active"*ngIf="pms.general.HmArchive">
      <mat-icon mat-list-icon>stars</mat-icon>
      HM ARCHIVE
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item matRipple [routerLink]="'/admin/shipments/masterShipments'" routerLinkActive="active"*ngIf="pms.general.HmStorage" >
      <mat-icon mat-list-icon>stars</mat-icon>
      HM STORAGE
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item matRipple [routerLink]="'/admin/accountings/businessAccounting'" routerLinkActive="active" *ngIf="pms.general.HmProfitAndLoss">
      <mat-icon mat-list-icon>money</mat-icon>
      HM PROFIT AND LOSS
  </a>
</mat-list>
