import { element } from 'protractor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalItem } from './../../models/local';
import { Observable, of as observableOf, Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ManagerService } from '../../shared/services/manager.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AuthenticationService } from '../../services/authentication.service';

import { Component, OnInit, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { map, startWith, tap, debounceTime, switchMap, catchError } from 'rxjs/operators';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { ConfirmComponent } from '../../shared/modal/confirm/confirm.component';


@Component({
  selector: 'app-createlocaltransportations',
  templateUrl: './createlocaltransportations.component.html',
  styleUrls: ['./createlocaltransportations.component.scss']
})
export class CreatelocaltransportationsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('itemTemplate') itemTemplate: TemplateRef<any>;

  @ViewChild('content', { read: ElementRef }) content: ElementRef;

  exportAsConfig: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'content'
  };

  exportAsConfig2: ExportAsConfig = {
    type: 'xls',
    elementIdOrContent: 'content'
  };

  public localForm: FormGroup;

  callSubject: Subject<void>;

  addNewLocal = false;

  loaded = false;
  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;
  submited: false;
  percentCompleted = 0;

  locationCtrl: FormControl = new FormControl('');

  displayedColumns: string[] = ['id', 'LOCATION', 'SAVANNAH', 'CALIFORNIA', 'NEWYORK', 'TEXAS', 'update', 'delete'];
  dataSource: MatTableDataSource<LocalItem> = new MatTableDataSource<LocalItem>();

  searchTerms = {
    Location: ''
  };

  pms: any = {};


  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private exportAsService: ExportAsService,
    private managerService: ManagerService,
    private dialog: MatDialog,
    private auth: AuthenticationService) {
    if (auth.currentUserValue.permission) {
      this.pms = auth.currentUserValue.permission.localtransportation;
      let filteredPermission = ['id', 'LOCATION', 'SAVANNAH', 'CALIFORNIA', 'NEWYORK', 'TEXAS'];
      this.displayedColumns = filteredPermission;
      console.log(this.pms);
      if(this.pms.update){
        this.displayedColumns.push('update');
        this.displayedColumns.push('delete');
      }
    }


    this.locationCtrl.valueChanges.subscribe(data => {
      this.searchTerms.Location = data;
      this.applyFilter(data);
    });
  }

  ngAfterViewInit(): void {
    this.activeNavigation();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.callSubject = new Subject();
    this.callSubject.pipe(
      tap(() => {
        this.isLoadingResults = true;
      }),
      debounceTime(500)
    ).subscribe(() => {
      this.activeNavigation();
    });

    this.resetForm();
  }

  // get local transportation's data
  activeNavigation(): void {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.managerService.getLocal({
            sort: this.sort.active,
            order: this.sort.direction,
            pagesize: this.paginator.pageSize,
            page: this.paginator.pageIndex + 1,
            filter: Object.keys(this.searchTerms).map(key => this.searchTerms[key]).join('|')
          });
        }),
        map(data => {

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;


          return data.data;

        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        if (data) {

          // const _data = data;

          // this.dataSource = new MatTableDataSource(_data);
          // this.dataSource.filterPredicate = (data: any, filter): boolean => {
          //   if (data.Location) {
          //     return data.Location.toLowerCase().indexOf(this.searchTerms.Location.toLowerCase()) !== -1;
          //   } else if (data.LOCATION) {
          //     return data.LOCATION.toLowerCase().indexOf(this.searchTerms.Location.toLowerCase()) !== -1;
          //   } else if (data.LOCATIONS) {
          //     return data.LOCATIONS.toLowerCase().indexOf(this.searchTerms.Location.toLowerCase()) !== -1;
          //   }

          // };
          this.dataSource = data;

        }

        this.loaded = true;
        setTimeout(() => {
          this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        }, 0);
      });
  }
  // clear inputs in add local transportation div
  resetForm(): void {
    this.localForm = this.formBuilder.group({
      local_id: [''],
      LOCATION: ['', Validators.required],
      SAVANNAH: ['', Validators.required],
      CALIFORNIA: ['', Validators.required],
      NEWYORK: ['', Validators.required],
      TEXAS: ['', Validators.required]
    });
  }
  // open add local transportation div
  openDiv(): void {
    if (this.addNewLocal === true) {
      this.addNewLocal = false;
    } else {
      this.addNewLocal = true;
    }
    this.resetForm();
  }

  openUpdateRow(data): void {
    data.isEdit = true;
  }
  closeUpdateRow(data): void {
    data.isEdit = false;
  }

  // save new local
  onSubmit(): void {
    const fdata: FormData = new FormData();
    fdata.append('LOCATION', this.localForm.controls.LOCATION.value);
    fdata.append('SAVANNAH', this.localForm.controls.SAVANNAH.value);
    fdata.append('CALIFORNIA', this.localForm.controls.CALIFORNIA.value);
    fdata.append('NEWYORK', this.localForm.controls.NEWYORK.value);
    fdata.append('TEXAS', this.localForm.controls.TEXAS.value);

    this.managerService.saveLocal(fdata).subscribe(data => {
      this.openSnackBar('Data submitted successfully', '');
      console.log('success');
      this.activeNavigation();
    });
    this.addNewLocal = false;
  }

  // update local data
  onchange(data, event: any, inputName: any=''): void {
    const fdata: FormData = new FormData();
    console.log(event)
    //const inputName = event.path[2]?.classList[3]?.replace('mat-column-', '');
    const value = event.target.value;

    if (inputName === 'LOCATION') {

      fdata.append('local_id', data.id);
      fdata.append('LOCATION', value);
      fdata.append('SAVANNAH', data.SAVANNAH);
      fdata.append('CALIFORNIA', data.CALIFORNIA);
      fdata.append('NEWYORK', data.NEWYORK);
      fdata.append('TEXAS', data.TEXAS);
      data.LOCATIONS = value;

    } else if (inputName === 'SAVANNAH') {

      fdata.append('local_id', data.id);
      fdata.append('LOCATION', data.LOCATIONS);
      fdata.append('SAVANNAH', value);
      fdata.append('CALIFORNIA', data.CALIFORNIA);
      fdata.append('NEWYORK', data.NEWYORK);
      fdata.append('TEXAS', data.TEXAS);
      data.SAVANNAH = value;

    } else if (inputName === 'CALIFORNIA') {

      fdata.append('local_id', data.id);
      fdata.append('LOCATION', data.LOCATIONS);
      fdata.append('SAVANNAH', data.SAVANNAH);
      fdata.append('CALIFORNIA', value);
      fdata.append('NEWYORK', data.NEWYORK);
      fdata.append('TEXAS', data.TEXAS);
      data.CALIFORNIA = value;

    } else if (inputName === 'NEWYORK') {

      fdata.append('local_id', data.id);
      fdata.append('LOCATION', data.LOCATIONS);
      fdata.append('SAVANNAH', data.SAVANNAH);
      fdata.append('CALIFORNIA', data.CALIFORNIA);
      fdata.append('NEWYORK', value);
      fdata.append('TEXAS', data.TEXAS);
      data.NEWYORK = value;

    } else if (inputName === 'TEXAS') {

      fdata.append('local_id', data.id);
      fdata.append('LOCATION', data.LOCATIONS);
      fdata.append('SAVANNAH', data.SAVANNAH);
      fdata.append('CALIFORNIA', data.CALIFORNIA);
      fdata.append('NEWYORK', data.NEWYORK);
      fdata.append('TEXAS', value);
      data.TEXAS = value;

    }

  }

  editRow(id, location, savannah, california, newyork, texas, data): void {
    const fdata: FormData = new FormData();
    fdata.append('local_id', id);
    fdata.append('LOCATION', location);
    fdata.append('SAVANNAH', savannah);
    fdata.append('CALIFORNIA', california);
    fdata.append('NEWYORK', newyork);
    fdata.append('TEXAS', texas);
    this.managerService.saveLocal(fdata).subscribe(item => {
      this.openSnackBar('Data submitted successfully', '');
      console.log('success');
    });
    data.isEdit = false;
  }

  // delete local row
  deleteRow(id): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: { title: 'Are you sure?', id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const fdata: FormData = new FormData();
        fdata.append('id', result);
        this.managerService.deleteLocal(fdata).subscribe(data => {
          this.activeNavigation();
          // this.dataSource.data = this.dataSource.data.filter(row => row.id !== result);
        });
      }

    });
  }

  // send mail
  sendMail(): void {
    const fdata: FormData = new FormData();
    this.managerService.sendMail(fdata).subscribe(data => {
      if (data) {
        this.openSnackBar('Mail Sent successfully', '');
      }
    });
  }
  // apply filter
  applyFilter(val): void {
    let filterValue = val.toString();
    this.paginator.pageIndex = 0;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.callSubject.next();
  }
  // reset filter
  resetFilter(): void {
    this.searchTerms = {
      Location: ''
    };

    this.locationCtrl.setValue('');
    this.dataSource.filter = '';
  }
  // save page as pdf
  savePDF(): void {
    document.body.classList.add('print_mode');
    this.exportAsService.save(this.exportAsConfig, 'local_transportations').subscribe(() => {
      setTimeout(() => { document.body.classList.remove('print_mode'); }, 1000);
    });
  }
  // save page as excel
  saveEXCEL(): void {
    document.body.classList.add('print_mode');
    this.exportAsService.save(this.exportAsConfig2, 'local_transportations').subscribe(() => {
      setTimeout(() => { document.body.classList.remove('print_mode'); }, 1000);
    });
  }
  // print page
  printPage(): void {
    window.print();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
